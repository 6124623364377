
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

const GAME_URL =
  'https://philip-morris-germany.leadfamly.com/pramienshop-survey';

export default defineComponent({
  setup() {
    const store = useStore();

    const userId = store.getters['authModule/userId'];
    const {
      headers: { 'x-login-token': token },
    } = store.getters['authModule/authHeaders'];

    return {
      token,
      userId,
      gameURL: GAME_URL
    }
  }
});
